'use client'

import { Button } from '@design-system/src/components/Button'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Text } from '@design-system/src/components/Text'
import { css, cx } from 'styled-system/css'

export default function GlobalError({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  console.error('Error in GlobalError page: error & digest:', error, error.digest)

  return (
    <html>
      <body>
        return (
        <div
          className={container}
          id="container"
          onMouseMove={event => {
            const elEyes = document.getElementsByClassName('eye') as HTMLCollectionOf<HTMLDivElement>
            if (elEyes.length) {
              const elEye = elEyes[0]
              const x = elEye.offsetLeft + elEye.clientWidth / 2
              const y = elEye.offsetTop + elEye.clientHeight / 2
              const rad = Math.atan2(event.pageX - x, event.pageY - y)
              const rot = rad * (180 / Math.PI) * -1 + 180
              // iterate through all eyes
              for (let i = 0; i < elEyes.length; i++) {
                const elEye = elEyes[i]
                elEye.style.transform = `rotate(${rot}deg)`
              }
            }
          }}>
          <CustomFlex justify="center" css={numberContainer}>
            <span className={errorNum}>5</span>
            <div className={cx(eye, 'eye')} />
            <div className={cx(eye, 'eye')} />
          </CustomFlex>
          <Text>
            Oh eyeballs! Something went wrong. We're <i>looking</i> to see what happened.
          </Text>
          <Button label="Go Back" variant="primary" onClick={() => reset()} />
        </div>
        )
      </body>
    </html>
  )
}

const container = css({
  backgroundColor: '$gs11',
  width: '[100vw]',
  height: '[100vh]',
  color: '$gs1',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '$6',
  alignItems: 'center',
})

const numberContainer = {
  fontFamily: '$body1',
} as const

const errorNum = css({
  fontSize: '[130px]',
  lineHeight: '[100px]',
})

const eye = css({
  background: '$gs1',
  borderRadius: '$round',
  display: 'inline-block',
  height: '[100px]',
  position: 'relative',
  width: '[100px]',
  _after: {
    background: '$gs12',
    borderRadius: '$round',
    bottom: '[56.1px]',
    content: '""',
    height: '[33px]',
    position: 'absolute',
    right: '[33px]',
    width: '[33px]',
  },
})
